import { Flex } from '@stars-ecom/shared-atoms-ui'
import { AccountEdit, AccountLayout } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountEditPage = (props) => {
  const { pageContext } = props

  const { isCheckout = false } = pageContext

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes infos personnelles - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Mes infos personnelles - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AccountLayout isCheckout={isCheckout}>
        <AccountEdit isCheckout={isCheckout} />
      </AccountLayout>
    </Flex>
  )
}

AccountEditPage.pageType = 'account'

export default AccountEditPage
